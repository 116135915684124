import React, { useMemo } from 'react';

import {
  BannerWarning,
  PrimaryButton,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackground from '../../../assets/images/HardeesBG.jpg';

const PrimaryHome = ({
  t = () => {},
  languages = [],
  healthMsg = true,
  isCurrentLang,
  primaryAction,
  secondaryAction,
  onChangeLanguage = () => {},
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const secondaryActionClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-button-large' : 'text-button-large px-20';
  }, [isCurrentLang]);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackground,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="primary-home"
        className="flex flex-col items-center justify-center w-full h-full"
      >
        <div
          data-test="home-upper"
          className={`z-10 absolute bottom-0 flex items-center justify-center w-full space-x-12 mb-${
            showTermsAndConditionsButton && healthMsg ? '128' : '96'
          } button-container`}
        >
          {!!primaryAction && (
            <PrimaryButton onClick={primaryAction} className="w-2/5 px-20 py-6 text-button-large">
              {t('homePrimaryAction')}
            </PrimaryButton>
          )}

          {!!secondaryAction && (
            <PrimaryButton
              data-test="button-secondary"
              className={`w-2/5 py-6 ${secondaryActionClass}`}
              onClick={secondaryAction}
            >
              {t('homeSecondaryAction')}
            </PrimaryButton>
          )}
        </div>

        <div className="primary-home__loadable-image-container" data-test="home-lower">
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className="flex-col w-11/12 mb-24 sm:flex-row sm:w-10/12 md:w-4/5 md:mb-32 md:flex"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`flex-col w-11/12 mb-${
                showTermsAndConditionsButton ? '2' : '24'
              } sm:flex-row sm:w-10/12 md:w-4/5 md:mb-${
                showTermsAndConditionsButton ? '2' : '32'
              } md:flex`}
            />
          )}
        </div>

        <div
          className="absolute bottom-0 flex flex-col items-center justify-center w-full mb-4 space-y-8"
          data-test="home-footer"
        >
          <p className="text-5xl tracking-wide">{t('languageSelectionTitle')}</p>
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
