import React, { useMemo } from 'react';

import {
  BannerWarning,
  PrimaryButton,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultHeroImage from '../../../assets/images/HardeesLogo.png';
import DefaultBackground from '../../../assets/images/HardeesBG.jpg';

const SecondaryHome = ({
  t = () => {},
  languages = [],
  healthMsg = true,
  isCurrentLang,
  primaryAction,
  secondaryAction,
  accessibilityEnabled = false,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
  onChangeLanguage = () => {},
}) => {
  const secondaryActionClass = useMemo(() => {
    return isCurrentLang('uae') ? 'text-button-large' : 'text-button-large px-20';
  }, [isCurrentLang]);

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackground,
        },
        hero: {
          image: DefaultHeroImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div
        data-test="secondary-home"
        className="z-10 flex flex-col items-center justify-center w-full h-full"
      >
        <div
          data-test="home-upper"
          className={`absolute bottom-0 flex flex-col items-center justify-center  w-full mb-${
            showTermsAndConditionsButton && healthMsg ? '128' : '96'
          } button-container`}
        >
          {!accessibilityEnabled && (
            <div className="flex flex-col items-center justify-center w-full">
              <HomeCustomisation.HeroImage />
            </div>
          )}

          <div className="flex items-center justify-center w-full ml-0">
            {!!primaryAction && (
              <PrimaryButton
                onClick={primaryAction}
                className="w-2/5 px-20 py-6 mr-4 text-button-large"
              >
                {t('homePrimaryAction')}
              </PrimaryButton>
            )}

            {!!secondaryAction && (
              <PrimaryButton
                onClick={secondaryAction}
                className={`w-2/5 ml-4 py-6 ${secondaryActionClass}`}
                data-test="button-secondary"
              >
                {t('homeSecondaryAction')}
              </PrimaryButton>
            )}
          </div>
        </div>

        <div className="secondary-home__loadable-image-container" data-test="home-lower">
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className="flex-col w-11/12 mb-24 sm:flex-row sm:w-10/12 md:w-4/5 md:mb-40 md:flex"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`flex-col w-11/12 mb-${
                showTermsAndConditionsButton ? '2' : '24'
              } sm:flex-row sm:w-10/12 md:w-4/5 md:mb-${
                showTermsAndConditionsButton ? '2' : '40'
              } md:flex`}
            />
          )}
        </div>
        <div
          className="absolute bottom-0 flex flex-col items-center justify-center w-full mb-8 space-y-8"
          data-test="home-footer"
        >
          <p className="text-5xl tracking-wide">{t('languageSelectionTitle')}</p>
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { SecondaryHome };
